/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/variables.scss';
@import 'assets/styles/mixins.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.background-dark {
  background-color: $dark;
}

.button-orange {
  border: 2px solid $orange;
  background: none;
  color: $orange !important; // maybe $dark
}

.loading {
  text-align: center;
}

.success {
  text-align: center;
  color: $success;
}

.error {
  text-align: center;
  color: $error;
}

.cursor-pointer {
  cursor: pointer;
}

// form design
form {
  button {
    width: 100%;
  }
}

// material form fields design
mat-form-field {
  width: 100% !important;

  // textarea resize option
  textarea {
    resize: vertical !important;
  }
}

// content below nav
.content {
  min-height: 100vh;
  padding-top: $nav-height + 10px;
  background-color: $dark;
  max-width: 100vw !important;
  @include center-content();
}

// reponsive tables global design
.tablecontainer {
  height: auto;
  max-height: 345px;
  overflow: auto;

  table {
    width: 100%;

    th {
      z-index: 1 !important;
    }

    td {
      a {
        color: black;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    mat-header-cell,
    mat-cell {
      padding-right: 10px;
    }
  }
}

.header-flex {
  justify-content: center !important;
  padding-left: 50px;
  padding-right: 50px;
}

// language selection button in nav
.language-selection-button {
  width: 150px !important;
  height: 50px !important;
  position: absolute !important;
  top: 9px !important;
  right: 30px !important;
  border-radius: 5px;
}

// menu button in nav
.menu-button-reponsive {
  position: absolute !important;
  top: 13px !important;
  right: 13px !important;

  mat-icon {
    transform: scale(1.2);
  }
}

// logout button in nav
.logout-button-non-reponsive {
  position: absolute !important;
  top: 14px;
  right: 205px;
  mat-icon {
    transform: scale(1.3);
  }
}

.firebase-emulator-warning {
  display: none;
}

// loading spinner
.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cover-spin::after {
  content: '';
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: white;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

// large responsive breakpoint
.responsive-lg {
  display: none !important;
}

.non-responsive-lg {
  display: block !important;
}

// medium and small responsive breakpoints
.responsive {
  display: none !important;
}

.non-reponsive {
  display: block !important;
}

@media screen and (max-width: 992px) {
  .responsive-lg {
    display: block !important;
  }

  .non-responsive-lg {
    display: none !important;
  }
}

@media screen and (max-width: 766px) {
  .responsive {
    display: block !important;
  }

  .non-responsive {
    display: none !important;
  }

  mat-panel-description {
    display: none !important;
  }

  .button-orange {
    font-size: 13px !important;
  }

  .header-flex {
    justify-content: left !important;
    padding-left: 20px !important;
    padding-right: 0 !important;
  }

  .language-selection-button {
    font-size: 14px !important;
    width: 100px !important;
    height: 45px !important;
    top: 12px !important;
    right: 10px !important;
  }
}
